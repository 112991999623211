import { size } from 'lodash';

const { axiosInstance } = require('./config');

export const getBusiness = () => axiosInstance.get('/businesses');

export const getTechnologies = () => axiosInstance.get('/technologies');

export const createClientRequest = (data) => axiosInstance.post('/client-request', data);

export const createClientRequestTechnologies = (data) => axiosInstance.post('/client-request/technologies', data);

export const getFeaturesByBusiness = (id) => axiosInstance.get(`/features/${id}`);

export const getClientRequestSummary = (id) => axiosInstance.get(`/client-technologies/${id}`);

export const getClientRequestDetails = (id) => axiosInstance.get(`/client-request-technology/details/${id}`);

export const getClientRequestList = (page, filters) =>
  axiosInstance.get(`/client-request?page=${page}`, {
    params: {
      ...(filters && filters),
    },
  });

export const getCitiesList = (page, search) =>
  axiosInstance.get(`/cities/search?page=${page}`, {
    params: {
      size: 10,
      name: search,
    },
  });

export const getCitiesListWithRegion = (page, size = 100, regionId, name = '') =>
  axiosInstance.get(`/cities/findByRegion`, {
    params: {
      page,
      size,
      regionId,
      name,
    },
  });
export const getDistrictListWithCity = (page, size = 100, cityId, name = '') =>
  axiosInstance.get(`/district/findByCity`, {
    params: {
      page,
      size,
      name,
      cityId,
    },
  });
export const getCitiesListWithoutSearch = () => axiosInstance.get(`/cities/listAll`);

export const getRegionsList = (page, search) =>
  axiosInstance.get(`/regions/search?page=${page}`, {
    params: {
      size: 10,
      name: search,
    },
  });
export const getRegionList = (page, search, name) =>
  axiosInstance.get(`/regions/listAll`, {
    params: {
      page,
      size: 100,
      name,
    },
  });

export const getFacilitiesList = (page, search) =>
  axiosInstance.get(`/facilities/search?page=${page}`, {
    params: {
      size: 10,
      name: search,
    },
  });
export const changeRequestStatus = (id, data) =>
  axiosInstance.post(`/client-request-technology/change-status/${id}`, data);

export const getUsersList = () => axiosInstance.get(`/users`);
export const getUsers = (page, search, status) =>
  axiosInstance.get(`/users/searchUser`, {
    params: {
      page,
      size: 10,
      keyword: search,
      status,
    },
  });

export const addUserRequest = (data) => axiosInstance.patch(`/globals/update`, data);

export const addRegionsRequest = (data) => axiosInstance.post(`/regions/add`, data);
export const addfacilitiesRequest = (data) => axiosInstance.post(`/facilities/add`, data);

export const addCityRequest = (data) => axiosInstance.post(`/cities/add`, data);
export const deleteCity = (id) => axiosInstance.delete(`/cities/delete`, { data: { id } });

export const deleteRegion = (id) => axiosInstance.delete(`/regions/delete`, { data: { id } });
export const deleteFacility = (id) => axiosInstance.delete(`/facilities/delete`, { data: { id } });

export const addServicesRequest = (data) => axiosInstance.post(`/services/add`, data);
export const deleteService = (id) => axiosInstance.delete(`/services/delete`, { data: { id } });
export const getServicesList = (page, search) =>
  axiosInstance.get(`/services/search?page=${page}`, {
    params: {
      size: 10,
      name: search,
    },
  });
export const getVatList = () => axiosInstance.get(`/globals/listAll`);
export const addpostSaleServicesRequest = (data) => axiosInstance.post(`/postSaleServices/add`, data);
export const deletepostSaleServices = (id) => axiosInstance.delete(`/postSaleServices/delete`, { data: { id } });
export const getpostSaleServicesList = (page, search) =>
  axiosInstance.get(`/postSaleServices/search?page=${page}`, {
    params: {
      size: 10,
      name: search,
    },
  });

export const addInterfacesRequest = (data) => axiosInstance.post(`/interfaces/add`, data);
export const deleteInterface = (id) => axiosInstance.delete(`/interfaces/delete`, { data: { id } });
export const getInterfacesList = (page, search) =>
  axiosInstance.get(`/interfaces/search?page=${page}`, {
    params: {
      size: 10,
      name: search,
    },
  });

export const addDocumentTypeRequest = (data) => axiosInstance.post(`/documentType/add`, data);
export const getDocumentTypeList = (page, search) =>
  axiosInstance.get(`/documentType/search?page=${page}`, {
    params: {
      size: 10,
      name: search,
    },
  });

export const getProjectsList = (page, search, status, size = 10) =>
  axiosInstance.get('/projects/searchProject', {
    params: {
      page,
      size,
      name: search,
      status,
    },
  });
export const findAllProjectsList = () => axiosInstance.get('/projects/findAllProject');
export const getUnitsList = (page, search, status, size = 10) =>
  axiosInstance.get('/units/searchUnit', {
    params: {
      page,
      size,
      name: search,
      status,
    },
  });
export const cloneUnit = (id) => axiosInstance.post(`/units/cloneNewUnit`, { id });
export const cloneLand = (id) => axiosInstance.post(`/lands/cloneNewLand`, { id });

export const getUnitsDetials = (id) => axiosInstance.get(`units/viewUnit/${id}`);
export const getLandsDetials = (id) => axiosInstance.get(`lands/viewLand/${id}`);
export const getListProjectSummaryDetails = () => axiosInstance.get(`/projects/listProjectSummaryDetails`);
export const getListPlanSummaryDetails = () => axiosInstance.get(`/plans/listPlanSummaryDetails`);

export const listAllProjects = () => axiosInstance.get(`/projects/listProjects`);
export const deleteDocumetType = (id) => axiosInstance.delete(`/documentType/delete`, { data: { id } });

export const getOwnersData = () => axiosInstance.get(`owners/listAll`);
export const deleteOwner = (id) => axiosInstance.delete(`owners/delete`, { data: { id } });

export const getConsultantData = () => axiosInstance.get(`consultant/listAll`);
export const addProject = (formData) => axiosInstance.post('/projects/addNewProject', formData);
export const editProject = (formData) => axiosInstance.patch('/projects/updateExistProject', formData);
export const getProjectRequestDetails = (id) => axiosInstance.get(`/projects/viewProject/${id}`);

export const getLookupsList = (type) =>
  axiosInstance.post(
    `utils/listLookup`,

    [type]
  );

export const addUnitFinancialData = (data) => axiosInstance.post(`/units/unitFinancialData`, data);
export const addUnit = (formData) => axiosInstance.post('/units/addNewUnit', formData);
export const editUnit = (formData) => axiosInstance.patch('/units/updateExistUnit', formData);

export const addPlan = (formData) => axiosInstance.post('/plans/addNewPlan', formData);

export const getPlansList = (page, search, status, size = 10) =>
  axiosInstance.get('/plans/searchPlan', {
    params: {
      page,
      size,
      name: search,
      status,
    },
  });

export const editPlan = (formData) => axiosInstance.patch('/plans/updateExistPlan', formData);
export const getPlanDetails = (id) => axiosInstance.get(`plans/viewPlan/${id}`);

export const addLand = (formData) => axiosInstance.post('/lands/addNewLand', formData);
export const addLandFinancialData = (data) => axiosInstance.post(`/lands/landFinancialData`, data);

export const getLandsList = (page, search, status, size = 10) =>
  axiosInstance.get('/lands/searchLand', {
    params: {
      page,
      size,
      name: search,
      status,
    },
  });

export const editLand = (formData) => axiosInstance.patch('/lands/updateExistLand', formData);

export const getListDocumentsByTargetData = (targetType) =>
  axiosInstance.get(`documentType/listDocumentsByTarget`, {
    params: {
      targetType,
    },
  });

export const addAdmin = (formData) => axiosInstance.post('/users/addAdmin', formData);
export const getlistAdmins = (page, search, status, size = 10) =>
  axiosInstance.get('/users/searchAdmins', {
    params: {
      page,
      size,
      keyword: search,
      status,
    },
  });
// candidates

export const getCandidatesList = (page, search, status, size = 10) =>
  axiosInstance.get('/users/searchCandidate', {
    params: {
      page,
      size,
      keyword: search,
      status,
    },
  });

export const assignCandidate = (formData) => axiosInstance.patch('/candidates/assignCandidate', formData);

// end of candidate

export const getJobs = () => axiosInstance.get('jobs/listAll');
export const editAdmin = (formData) => axiosInstance.patch('/users/updateAdmin', formData);

export const addJobTitle = (formData) => axiosInstance.post('/jobs/add', formData);
export const getlistJobTitles = (page, search, size = 10) =>
  axiosInstance.get('/jobs/search', {
    params: {
      page,
      keyword: search,
      size,
    },
  });

export const editJobTitle = (formData) => axiosInstance.patch('/jobs/update', formData);
export const deleteJobTitle = (id) => axiosInstance.delete(`/jobs/delete`, { data: { id } });

// export const getListDocumentsByTargetData = (id) => axiosInstance.delete(`/documentType/delete`, { data: { id } });

export const addBuilding = (formData) => {
  return axiosInstance.post('/building/add', formData);
};

export const getBuildingsList = (page, search, size = 10) =>
  axiosInstance.get('/building/search', {
    params: {
      page,
      size,
      keyword: search,
    },
  });

export const editBuilding = (formData) => axiosInstance.patch('/building/update', formData);
export const deleteBuilding = (id) => axiosInstance.delete(`/building/delete`, { data: { id } });

export const getReservationRequestssList = (page, keyword, size = 10) =>
  axiosInstance.get('/reservation/listAllReservationRequests', {
    params: {
      page,
      size,
      keyword,
    },
  });

export const getOwnersList = (search, page, size = 10) =>
  axiosInstance.get('/owners/search', {
    params: {
      page,
      keyword: search,
      size,
    },
  });

export const addOwner = (formData) => axiosInstance.post('/owners/addOwner', formData);

export const updateOwner = (formData) => axiosInstance.patch('/owners/updateOwner', formData);
export const getListAdminsNames = (keyword = '') =>
  axiosInstance.get(`/users/findAdminLookups`, {
    params: {
      keyword,
    },
  });
export const getBuildingListWithProjectId = (id) => axiosInstance.get(`/building/projectBuildingList?projectId=${id}`);
export const getProjectListDetails = (id) => axiosInstance.get(`/projects/listProjectDetails?projectId=${id}`);
export const getRoomCategoryList = () => axiosInstance.get(`/roomCategory/listAll`);

export const getDashboard = (type, value, start, end, filter = 'ALL') =>
  axiosInstance.get('/dashboard/getDashboard', {
    params: {
      type,
      value,
      start,
      end,
      filter,
    },
  });

export const generateReport = (type, value, start, end, filter = 'ALL') =>
  axiosInstance.get(`/dashboard/exportExcel`, {
    params: {
      type,
      value,
      start,
      end,
      filter,
    },
    responseType: 'blob',
  });

export const forgetPassword = (email) =>
  axiosInstance.get('/users/forgetPassword', {
    params: {
      email,
    },
  });

export const getManageRoles = (roleId) =>
  axiosInstance.get('/roles/manageRole', {
    params: {
      roleId,
    },
  });

export const addRoleRequest = (data) => {
  console.log(data);
  return axiosInstance.post(`/roles/saveRole`, data);
};

export const getRolesList = (page, search, size = 10, status) =>
  axiosInstance.get('/roles/search', {
    params: {
      page,
      name: search,
      active: status,
      size,
    },
  });
export const downloadFile = (name) =>
  axiosInstance.get('/utils/downloadFile', {
    params: {
      name,
    },
  });

export const getInvoiceDetails = (id) =>
  axiosInstance.get(`/reports/generateInvoice?reportName=invoice&id=${id}`, {
    responseType: 'blob',
  });

export const deletePictures = async (id) => {
  try {
    const response = await axiosInstance.delete('/pictures/delete', { data: { id } });
    return response.data;
  } catch (error) {
    console.error('Error deleting picture:', error);
    throw error;
  }
};
export const deleteSketch = async (id) => {
  try {
    const response = await axiosInstance.delete('/sketch/delete', { data: { id } });
    return response.data;
  } catch (error) {
    console.error('Error deleting picture:', error);
    throw error;
  }
};
export const deleteAttachments = async (id) => {
  try {
    const response = await axiosInstance.delete('/attachments/delete', { data: { id } });
    return response.data;
  } catch (error) {
    console.error('Error deleting picture:', error);
    throw error;
  }
};

// Rewview Requests

export const getFilterUnderReviewProducts = (page, search, size = 10) =>
  axiosInstance.get('/WFRequests/filterUnderReviewProducts', {
    params: {
      page,
      size,
      keyword: search,
    },
  });
export const getfilterPublishedProducts = (page, search, size = 10) =>
  axiosInstance.get('/WFRequests/filterPublishedProducts', {
    params: {
      page,
      size,
      keyword: search,
    },
  });

export const createApproveWFRequest = (data) => axiosInstance.post('/WFRequests/approveWFRequest', data);

export const createRejectWFRequest = (data) => axiosInstance.post('/WFRequests/rejectWFRequest', data);

export const getTerm_condition = () => axiosInstance.get(`/termsPrivacy/getTermsDetails`);
export const saveTerm_condition = (data) => axiosInstance.patch('/termsPrivacy/updateTermsDetails', data);
export const getTaxExemption = () => axiosInstance.get(`/termsPrivacy/getTaxDetails`);
export const saveTaxExemption = (data) => axiosInstance.patch('/termsPrivacy/updateTaxDetails', data);

export const getPrivacyTerms = () => axiosInstance.get(`/termsPrivacy/getPrivacyDetails`);
export const savePrivacyTerms = (data) => axiosInstance.patch('/termsPrivacy/updatePrivacyDetails', data);

export const getemployeeNotificationsList = (page, size = 5) =>
  axiosInstance.get('/employeeNotifications/employeeNotificationList', {
    params: {
      page,
      size,
    },
  });
export const uploadFile = (data) => axiosInstance.post('/utils/uploadFile', data);
export const getPublicQuestions = (page, size = 100) =>
  axiosInstance.get('/publicQuestions/listPublicQuestion', {
    params: {
      page,
      size,
    },
  });
export const addPublicQuestion = (data) => axiosInstance.post('/publicQuestions/addQuestion', data);
export const updatePublicQuestion = (data) => axiosInstance.patch('/publicQuestions/updateQuestion', data);

export const getSeenNotification = (id) => axiosInstance.get(`/employeeNotifications/seenNotification/${id}`);

export const getUserDetails = (id) => axiosInstance.get(`/users/getUserDetails/${id}`);
export const updatePassword = (data) => axiosInstance.patch('/users/updateAdminPassword', data);

export const updateAdminProfile = (data) => axiosInstance.patch('/users/updateProfileImage', data);

export const getListNewOwnershipRequests = (page, search, size = 10) =>
  axiosInstance.get('/ownership/listNewOwnershipRequests', {
    params: {
      page,
      size,
      
      keyword: search,
    },
  });

export const getListCompletedOwnershipRequests = (page, search, size = 10) =>
  axiosInstance.get('/ownership/listCompletedOwnershipRequests', {
    params: {
      page,
      size,
      keyword: search,
    },
  });

export const assignOwnershipRequest = (data) => axiosInstance.post(`/ownership/assignOwnershipRequest`, data);

export const updateOwnershipRequest = (data) => axiosInstance.post(`/ownership/updateOwnershipRequest`, data);
export const viewOwnershipRequestDetials = (id) => axiosInstance.get(`/ownership/viewOwnershipRequestDetails/${id}`);

export const updateReservationRequestStatus = (data) =>
  axiosInstance.post(`/reservation/updateReservationRequest`, data);

export const getReportsAllList = () => axiosInstance.get(`/reports/listAll`);
export const generateDashboardReport = (data) => axiosInstance.post(`/reports/generateReport`, data);

export const getActivityLog = (data, page, size = 10) =>
  axiosInstance.post(`/logFollow/search?page=${page}&size=${size}`, data);

// export const downloadReport = (data) => axiosInstance.get(`/utils/downloadFile`, { params: { name: data } });

// services api
export const addOurServices = (formData) => axiosInstance.post('/ourServices/add', formData);
export const deleteOurService = (id) =>
  axiosInstance.delete('/ourServices/delete', {
    data: {
      id,
    },
  });

export const getOurServicesList = (page, search, size = 10) =>
  axiosInstance.get('/ourServices/search', {
    params: {
      page,
      size,
      keyword: search,
    },
  });

export const editOurServices = (formData) => axiosInstance.patch('ourServices/update', formData);

// offers api
export const addOurOffer = (formData) => axiosInstance.post('/ourOffers/add', formData);
export const deleteOurOffer = (id) =>
  axiosInstance.delete('/ourOffers/delete', {
    data: {
      id,
    },
  });

export const getOurOffersList = (page, search, size = 10) =>
  axiosInstance.get('/ourOffers/search', {
    params: {
      page,
      size,
      keyword: search,
    },
  });
export const editOurOffer = (formData) => axiosInstance.patch('ourOffers/update', formData);

export const getTechnicalSupportList = (page, search, status, size = 10) =>
  axiosInstance.get('/contactRequest/search', {
    params: {
      page,
      size,
      keyword: search,
      category: status,
    },
  });
export const replyRequest = (formData) => axiosInstance.patch('contactRequest/replyRequest', formData);
export const getContactsList = (page, search, size = 10) =>
  axiosInstance.get('/globals/searchContacts', {
    params: {
      page,
      size,
      keyword: search,
    },
  });

export const updateContact = (formData) => axiosInstance.post('/globals/updateGlobal', formData);
export const changeLanguage = (lang) =>
  axiosInstance.get('/users/switchLanguage', {
    params: {
      lang: lang?.toUpperCase(),
    },
  });
